import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import {
  ApplicationSortingField,
  ICreateApplicationParams,
  IApplicationViewModel,
  ICreateApplicationByIntermediaryParams,
} from 'api/digifi/ApplicationsApi';
import { ISortType } from 'types/SortType';
import { applicationsApi } from 'store/api';
import rejectWithValue from 'utils/rejectWithValue';
import { getCurrentApplication } from './activeBorrowerInformationSlice';
import { IReduxState } from 'types/ReduxState';

enum ApplicationsActionType {
  CreateApplication = 'applications/createApplication',
  createApplicationByIntermediary = 'applications/createApplicationByIntermediary',
  GetBorrowerApplications = 'applications/getBorrowerApplications',
  FindByDisplayId = 'applications/findByDisplayId',
  ChangeSearch = 'applications/changeSearch',
  ChangeFilters = 'applications/changeFilters',
}

export interface IGetBorrowerApplicationParams {
  offset: number;
  count: number;
  sortType: ISortType<ApplicationSortingField>;
  productId?: string;
  search?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  onlyInFinalStatus?: boolean;
  onlyInProgress?: boolean;
  onlyInApprovedStatus?: boolean;
  onlyInRejectedStatus?: boolean;
}

interface IIntermediaryApplicationsFilters {
  tabIndex: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  productId?: string;
}

interface IIntermediaryApplicationsSearch {
  tabIndex: string;
  search: string;
}

const applicationsAdapter = createEntityAdapter<IApplicationViewModel>();
const searchAdapter = createEntityAdapter<IIntermediaryApplicationsSearch>({ selectId: ({ tabIndex }) => tabIndex });
const filtersAdapter = createEntityAdapter<IIntermediaryApplicationsFilters>({ selectId: ({ tabIndex }) => tabIndex });

export type IApplicationsState = {
  applications: EntityState<IApplicationViewModel>;
  search: EntityState<IIntermediaryApplicationsSearch>;
  filters: EntityState<IIntermediaryApplicationsFilters>;
};

const initialState: IApplicationsState = {
  applications: applicationsAdapter.getInitialState(),
  search: searchAdapter.getInitialState(),
  filters: filtersAdapter.getInitialState(),
};

export const createApplication = createAsyncThunk(
  ApplicationsActionType.CreateApplication,
  async (params: ICreateApplicationParams, thunkApi) => {
    try {
      return await applicationsApi.createApplication(params);
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const createApplicationByIntermediary = createAsyncThunk(
  ApplicationsActionType.createApplicationByIntermediary,
  async (params: ICreateApplicationByIntermediaryParams, thunkApi) => {
    try {
      return await applicationsApi.createApplicationByIntermediary(params);
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const getBorrowerApplications = createAsyncThunk(
  ApplicationsActionType.GetBorrowerApplications,
  async (params: IGetBorrowerApplicationParams, thunkApi) => {
    try {
      const applications = await applicationsApi.getBorrowerApplications({
        count: params.count,
        offset: params.offset,
        sortField: params.sortType.field,
        sortDirection: params.sortType.ascending ? 'asc' : 'desc',
        search: params.search,
        onlyInFinalStatus: params.onlyInFinalStatus,
        onlyInProgress: params.onlyInProgress,
        onlyInApprovedStatus: params.onlyInApprovedStatus,
        onlyInRejectedStatus: params.onlyInRejectedStatus,
        createdAtFrom: params.createdAtFrom,
        createdAtTo: params.createdAtTo,
        productId: params.productId,
      });

      return {
        items: applications.applications,
        total: applications.total,
      };
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const findApplicationByDisplayId = createAsyncThunk(
  ApplicationsActionType.FindByDisplayId,
  async (displayId: string, thunkApi) => {
    try {
      return await applicationsApi.findByDisplayId(displayId);
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const changeSearch = createAction<IIntermediaryApplicationsSearch>(ApplicationsActionType.ChangeSearch);

export const changeFilters = createAction<IIntermediaryApplicationsFilters>(ApplicationsActionType.ChangeFilters);

const applicationsSlice = createSlice({
  name: 'applicationsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createApplication.fulfilled, (state, { payload }) => {
        applicationsAdapter.setOne(state.applications, payload);
      })
      .addCase(createApplicationByIntermediary.fulfilled, (state, { payload }) => {
        applicationsAdapter.setOne(state.applications, payload);
      })
      .addCase(getCurrentApplication.fulfilled, (state, { payload }) => {
        if (payload) {
          applicationsAdapter.setOne(state.applications, payload);
        }
      })
      .addCase(getBorrowerApplications.fulfilled, (state, { payload }) => {
        applicationsAdapter.addMany(state.applications, payload.items);
      })
      .addCase(findApplicationByDisplayId.fulfilled, (state, { payload }) => {
        applicationsAdapter.setOne(state.applications, payload);
      })
      .addCase(changeSearch, (state, { payload }) => {
        searchAdapter.setOne(state.search, payload);
      })
      .addCase(changeFilters, (state, { payload }) => {
        filtersAdapter.setOne(state.filters, payload);
      });
  },
});

const getApplications = (state: IReduxState) => state.applications;

export const createApplicationsSelector = () => {
  return createSelector([getApplications], (applications) => {
    return applications.applications.entities;
  });
};

export const createFiltersByTabIndexSelector = (tabIndex: string) => {
  return createSelector([getApplications], (applications) => {
    return applications.filters.entities[tabIndex] || null;
  });
};

export const createSearchByTabIndexSelector = (tabIndex: string) => {
  return createSelector([getApplications], (applications) => {
    return applications.search.entities[tabIndex] || null;
  });
};

export default applicationsSlice.reducer;
